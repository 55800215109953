import axiosIns from '@/libs/axios'
import { objectToUrlParam } from '@/libs/helper'

export default class MetatraderApis {
  getAllGroups = async data => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/groups${objectToUrlParam(data)}`);

  createAccount = async data => await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/account/create`, data);

  getPlatforms = async () => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/platforms`);

  getLeverages = async () => await axiosIns.get('/mt4webregister/leverages');

  getGroups = async () => await axiosIns.get('/mt4webregister/groups');

  getAccounts = async () => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/account`)

  saveChangePassword = async data => await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/account/password/update`, data)

  wireDepositList = async data => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/deposit/wire?page=${data.current_page}&per_page=${data.per_page}`)

  saveWireDeposit = async (data, streamFunction) => {
    const formData = new FormData()
    Object.keys(data).forEach(element => formData.append(element, data[element]))
    return await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/deposit/wire/store`, formData, {
      onUploadProgress: e => {
        if (streamFunction) {
          streamFunction(e)
        }
      },
    })
  }

  wireWithdrawList = async data => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/withdrawal/wire${objectToUrlParam(data)}`)

  saveWireWithdrawal = async data => await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/withdrawal/wire/store`, data)

  currencyList = async data => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/currency/list`, { params: data })

  bankList = async () => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/bank/list`)

  cryptoDepositList = async data => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/deposit/crypto`, { params: data })

  withdrawalDepositList = async data => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/withdrawal/crypto`, { params: data })

  saveCryptoWithdrawal = async data => await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/withdrawal/crypto/store`, data)

  saveCryptoDeposit = async (data, streamFunction) => {
    const formData = new FormData()
    Object.keys(data).forEach(element => formData.append(element, data[element]))
    return await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/deposit/crypto/store`, formData, {
      onUploadProgress: e => {
        if (streamFunction) {
          streamFunction(e)
        }
      },
    })
  }

  accountSetting = async (platform, id) => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/account/setting/${platform}/${id}`)

  saveSetting = async data => await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/account/setting`, data)

  transfer = async data => await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/account/transfer/balance`, data)

  tradeHistory = async (platform, id, data) => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/close-order/${platform}/${id}?page=${data.current_page}&per_page=${data.per_page}`)

  transferHistory = async (platform, id, data) => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/history/deposit-withdrawal/${platform}/${id}?page=${data.current_page}&per_page=${data.per_page}`)

  demoGroups = async data => await axiosIns.get(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/demo/groups${objectToUrlParam(data)}`)

  createDemoAccount = async data => await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/demo/external/account/create`, data)

  withdrawalSendOtp = async data => await axiosIns.post(`${process.env.VUE_APP_FOREX_BASE_API_URL}/forex/platform/withdrawal/send-otp`, data)
}
